

.setLoading{
  background-color: #fff;
  position: fixed;
  width: 100%;
  height: 100vh;
  left: 0;
  bottom: 0;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  img{
    height: 70px;
    transform: scale(1.4);
    animation: pulse 2s infinite;
  }
  @keyframes pulse {
    0% {
      transform: scale(1);
      opacity: 0;
    }
  
    70% {
      transform: scale(1.1);
      opacity: 1;
    }
  
    100% {
      transform: scale(1);
      opacity: 0;
    }
  }
}

