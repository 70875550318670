.subtitle {
    position: relative;

    h4 {
        position: absolute;
        left: 0;
        top: 40%;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 24.65px;
        letter-spacing: -0.36px;
        margin-bottom: 0;
    }
}