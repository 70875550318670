.main__header {
  .header {
    transition: all 0.5s;
    z-index: 997;
    padding: 30px 0;

    &.bg-white {
      padding: 15px 0;
    }

    .navbar {
      .menu-toggle {
        width: 40px;
        height: 20px;
        position: relative;
        margin-left: 20px;
        cursor: pointer;

        .line {
          width: 100%;
          height: 4px;
          background-color: #000;
          position: absolute;
          transition: transform 0.3s ease;
          &.home-line{
            background-color: #fff;
          }

          &:nth-child(1) {
            top: 0;
          }

          &:nth-child(2) {
            bottom: 0;
          }
        }

        &.active {
          .line {
            background-color: #000;
          }

          .line:nth-child(1) {
            transform: translateY(10px) rotate(45deg);
          }

          .line:nth-child(2) {
            transform: translateY(-6px) rotate(-45deg);
          }
        }
      }
    }

    .logo {
      &.logo-home {
        img {
          filter: brightness(0) invert(1) !important;
        }
      }

      img {
        width: 240px;
      }
    }

    &.sticked {
      background: #fff;
      padding: 15px 0;
      border-bottom: 1px solid #f3f3f3;

      .menu-toggle .line {
        background-color: #000;
        transition: transform 0.3s ease;
        &.home-line{
          background-color: #000;
        }
      }

      .logo-home {
        img {
          filter: brightness(0) invert(0) !important;
        }
      }

      .nav div {
        background-color: #000;
      }
    }
  }

  // MENU ITEMS
  .menu__lists {
    background-color: var(--color-primary);
    position: fixed;
    width: 100%;
    z-index: 99;
    top: -200%;
    cursor: pointer;
    transition: transform 0.3s ease;
    overflow-x: auto;

    &.show {
      top: 0;

      ul {
        li:nth-child(1) {
          animation: 0.5s fadeInUp;
        }

        li:nth-child(2) {
          animation: 1s fadeInUp;
        }

        li:nth-child(3) {
          animation: 1.5s fadeInUp;
        }

        li:nth-child(4) {
          animation: 2s fadeInUp;
        }

        li:nth-child(5) {
          animation: 2.5s fadeInUp;
        }

        li:nth-child(6) {
          animation: 3s fadeInUp;
        }
      }

      @-webkit-keyframes fade-up {
        0% {
          -webkit-transform: translateY(100%);
          transform: translateY(100%);
          visibility: visible;
        }

        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }

      @keyframes slideInUp {
        0% {
          -webkit-transform: translateY(100%);
          transform: translateY(100%);
          visibility: visible;
        }

        100% {
          -webkit-transform: translateY(0);
          transform: translateY(0);
        }
      }
    }

    .menu__items {
      height: 100vh;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding: 30px 0;

      ul {
        padding-top: 10px;
        list-style-type: none;
        padding-left: 0;
        line-height: 1.3;
        padding-top: 150px;

        li {
          font-size: 55px;
          font-weight: 600;
          text-transform: capitalize;

          a {
            color: #000;
            text-decoration: none;
          }
        }
      }

      .logo__menu {
        padding-top: 100px;
        padding-bottom: 30px;
        animation: 1.5s fadeInUp;

        img {
          width: 100%;
        }
      }
    }
  }

  @keyframes fadeInUp {
    0% {
      opacity: 0;
      transform: translateY(-20px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &.show {
    .sticked {
      padding: 30px 0;
    }

    .btn {
      display: none;
    }

    .logo.logo-home img {
      filter: brightness(0) invert(0) !important;
    }

    .sticked {
      background: transparent;
      border: none;
    }
  }
}