.wuudly__innerBanner {
    width: 100%;
    height: 500px;
    object-fit: cover;
    background-size: cover;
    margin-top: 95px;
    display: flex;
    align-items: end;
    position: relative;
    &::after{
        position: absolute;
        content: '';
        background: rgba(8, 11, 35, 0.30);
        width: 100%;
        height: 100%;
        z-index: 1;
    }

    h1 {
        color: #fff;
        color: #fff;
        font-size: 75px;
        font-family: var(--font-primary);
        z-index: 11;
        position: relative;
        padding-bottom: 100px;
        animation-name: fade-in;
    } 
    @keyframes fade-in {
        from {
          opacity: 0;
        }
      
        to {
          opacity: 1;
        }
      }
}